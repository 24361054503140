import { Fragment } from 'react'
import Helmet from 'react-helmet'
import { Redirect, Route, Switch } from 'react-router-dom'

import Container from '@material-ui/core/Container'
import { useTheme } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import ElevateAppBar from './components/Header'
import BookingPage from './pages/Booking'
import Consent from './pages/Consent'
import Contact from './pages/Contact'
import Home from './pages/Home'
import Services from './pages/Services'

function App() {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Fragment>
      <Helmet title="Be Compassion" />
      <ElevateAppBar isMobile={isMobile} />
      <Toolbar />
      <Container className="content">
        {isMobile ? <Toolbar /> : null}
        <Switch>
          <Route exact path="/" component={Home}></Route>
          <Route exact path="/services" component={Services}></Route>
          <Route exact path="/contact" component={Contact}></Route>
          <Route
            exact
            path="/booking"
            render={() => <BookingPage isMobile={isMobile} />}
          ></Route>
          <Route
            exact
            path="/consent"
            render={() => <Consent isMobile={isMobile} />}
          ></Route>
          <Route render={() => <Redirect to="/" />}></Route>
        </Switch>
      </Container>
    </Fragment>
  )
}

export default App
