import { Helmet } from 'react-helmet'
import { Link as RouterLink } from 'react-router-dom'

import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

const useServicesStyles = makeStyles(({ breakpoints }: Theme) =>
  createStyles({
    subtitle: {
      fontFamily: `'Dancing Script'`,
    },
    mainLogo: {
      maxWidth: 600,
      width: `100%`,
    },
    wrapper: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    },
  })
)

export default function Services() {
  const classes = useServicesStyles()

  return (
    <div>
      <Helmet title="Services | Be Compassion" />
      <Typography
        align="center"
        className={classes.subtitle}
        variant="h4"
        gutterBottom
      >
        Counseling
      </Typography>
      <Typography variant="body1" paragraph>
        Arianna has been working as a counselor in New Mexico since 2014. She is
        an independently Licensed Counselor in the state of New Mexico (LPCC)
        and a Nationally Certified Counselor (NCC). Her clinical experience
        includes assisting in managing community mental health clinics,
        implementing SBIRT (Screening, Brief Intervention, Referral to
        Treatment), and volunteering at Indian Health Services in New Mexico.
        Her professional goals as a counselor are to be an advocate for social
        justice through empowerment and community outreach.
      </Typography>
      <Box
        display="flex"
        justifyContent="space-around"
        flexGrow="1"
        marginBottom={1}
      >
        <Button
          color="secondary"
          component={RouterLink}
          to="/consent"
          variant="outlined"
        >
          sign the consent for counseling
        </Button>
        <Button
          color="secondary"
          component={RouterLink}
          to="/booking"
          variant="outlined"
        >
          Book Appointment
        </Button>
      </Box>

      <Typography
        align="center"
        className={classes.subtitle}
        variant="h4"
        gutterBottom
      >
        Supervision
      </Typography>
      <Typography variant="body1" paragraph>
        Arianna has provided supervision to practicum and interns since 2016.
        Her approach is to encourage supervisees to develop into the best
        counselor they can be. Arianna believes the key to supervision is to
        maintain open and honest communication. Supervision consists of
        advising, instructing, supporting, sharing, consulting, modeling,
        monitoring, and evaluating supervisees. Supervisee are supported to grow
        their counseling skills through case conceptualization, emotional
        awareness, professional development, integrating their theory and
        self-evaluation.
      </Typography>
      <Typography
        align="center"
        className={classes.subtitle}
        variant="h4"
        gutterBottom
      >
        {'Consultation & Training'}
      </Typography>
      <Typography variant="body1" paragraph>
        Arianna provides training on many topics and is a consultant in her
        areas of expertise. Her professional background includes working in
        behavioral health research and program evaluation, specializing in
        substance abuse prevention and early childhood development. Her career
        is focused on promoting social justice, equity, cultural humility and
        interpersonal communication. She has been trained as a mediator
        including an internship with the UNM Ombuds Services for Staff.
        Arianna’s expertise includes training in community-based participatory
        research, trauma-informed counseling, and program evaluation.
      </Typography>
    </div>
  )
}
