import {
  ChangeEvent,
  FormEvent,
  Fragment,
  useCallback,
  useRef,
  useState,
} from 'react'
import { Helmet } from 'react-helmet'
import { Link as RouterLink } from 'react-router-dom'
import SignatureCanvas from 'react-signature-canvas'

import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'
import LinearProgress from '@material-ui/core/LinearProgress'
import Link from '@material-ui/core/Link'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'

import DownloadPDF from '../utils/generate-consent-pdf'

const useConsentStyles = makeStyles(({ spacing, palette, shape }: Theme) =>
  createStyles({
    signatureCanvas: {
      backgroundColor: palette.background.paper,
      borderRadius: shape.borderRadius,
      borderColor: palette.primary.main,
      borderWidth: spacing(0.25),
      borderStyle: 'solid',
    },
  })
)

export default function Consent({ isMobile }: { isMobile: boolean }) {
  const classes = useConsentStyles()
  const [locked, setLocked] = useState(true)
  const [invalid, setInvalid] = useState(false)
  const [password, setPassword] = useState('')
  const [dialogOpen, setDialogOpen] = useState(false)
  const [q1, setQ1] = useState(false)
  const [q2, setQ2] = useState(false)
  const [q3, setQ3] = useState(false)

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [sign, setSign] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const canvasRef = useRef<any>(null)

  const handleEmail = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => setEmail(event.target.value),
    [setEmail]
  )
  const handleName = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => setName(event.target.value),
    [setName]
  )
  const handleQ1 = useCallback((_, checked) => setQ1(checked), [setQ1])
  const handleQ2 = useCallback((_, checked) => setQ2(checked), [setQ2])
  const handleQ3 = useCallback((_, checked) => setQ3(checked), [setQ3])

  const handleSignClick = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault()

      if (!name) {
        window.alert('Please enter a name')
        return
      }
      if (!email) {
        window.alert('Please enter an email address')
        return
      }
      if (!sign) {
        window.alert('Please draw your signature on the white block')
        return
      }

      setSubmitting(true)

      const signature = canvasRef.current
        .getTrimmedCanvas()
        .toDataURL('image/png')

      DownloadPDF(name, email, signature).then(() => {
        setDialogOpen(false)
        setSubmitting(false)
      })
    },
    [name, email, sign]
  )

  return (
    <Fragment>
      <Helmet title="Consent Form | Be Compassion" />
      {locked ? (
        <Dialog
          open
          maxWidth="sm"
          fullWidth
          fullScreen={isMobile}
          PaperProps={
            {
              component: 'form',
              onSubmit: (event: FormEvent<HTMLFormElement>) => {
                event.preventDefault()

                if (password === 'SignMe') {
                  setLocked(false)
                } else {
                  setLocked(true)
                  setInvalid(true)
                }
              },
            } as any
          }
        >
          <DialogContent>
            <Box display="flex" height="100%" alignItems="center" flexGrow="1">
              <TextField
                required
                fullWidth
                variant="outlined"
                label="Unlock code"
                value={password}
                error={invalid}
                onChange={(event) => setPassword(event.target.value)}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              fullWidth
              variant="contained"
              to="/services"
              component={RouterLink}
            >
              Cancel
            </Button>
            <Button
              fullWidth
              color="secondary"
              variant="contained"
              type="submit"
            >
              Unlock
            </Button>
          </DialogActions>
        </Dialog>
      ) : (
        <Grid spacing={2} container>
          <Grid item xs={12}>
            <Helmet title="Consent Form | Be Compassion" />
            <Typography component="h1" variant="h4" align="center" gutterBottom>
              {'Professional Disclosure & Informed Consent for Counseling'}
            </Typography>
            <Typography component="h3" variant="h6" align="center">
              Be-Compassion, LLC
            </Typography>
            <Typography component="h3" variant="h6" align="center" gutterBottom>
              Arianna Trott, MA, LPCC
              <br />
              <Link color="secondary" href="tel:505-310-9924">
                (505) 310-9924
              </Link>
              <br />
              <Link color="secondary" href="mailto:arianna@be-compassion.com">
                arianna@be-compassion.com
              </Link>
            </Typography>
            <Typography component="h3" variant="h6" gutterBottom>
              Consent for Counseling
            </Typography>
            <Typography variant="body1" paragraph>
              Be-Compassion, LLC provides counseling services to community
              individuals, couples, and families.
            </Typography>
            <Typography component="h3" variant="h6" gutterBottom>
              What is counseling?
            </Typography>
            <Typography variant="body1" paragraph>
              Counseling is a collaborative process. For counseling to be most
              effective, it is absolutely essential that you take an active
              role. Counselors at Be-Compassion, LLC use a solution-focused and
              humanistic-based counseling theory, that people are the experts in
              their own lives, responsible for their behaviors, and also
              responding to circumstances beyond their control (such as
              prejudice and oppression). The counseling session is your space to
              explore your feelings, attitudes, and beliefs about your life. It
              is a safe space to gain personal insight and make changes in your
              life. The process may open up awareness and provoke realizations
              that may cause uncomfortable feelings such as sadness, guilt,
              anxiety, anger, pain, frustration, loneliness, or helplessness.
              Counselors at Be-Compassion, LLC believe that the counseling
              relationship assists you in exploring and resolving difficult life
              issues and that people can learn to deal with and overcome
              obstacles that are placed in front of them with the right tools.
              This process of growth may cause significant impacts to
              employment, lifestyles, and relationships. No guarantees regarding
              outcomes can be made. If you are feeling uncomfortable at any time
              please notify your counselor and to discuss your feelings and
              thoughts. If for any reason a referral to another counselor is
              needed, Be-Compassion, LLC is required to assist you with finding
              a more appropriate counselor. Additionally, Be-Compassion, LLC
              cannot specify how many sessions will be appropriate to address
              your particular needs. If at any time you have questions about
              your counselors’ counseling theory, please do not hesitate to ask
              and they will clarify any questions you have.Your feedback on the
              process and counseling relationship are critical to gaining the
              greatest benefit from our work together. If you feel that your
              needs are not being met, Be-Compassion, LLC encourages you to
              speak with your counselor. Your counselor will do their best to
              resolve any issues. If you feel that any professional or ethical
              standards have been violated, you may file a complaint with the
              New Mexico Counseling and Therapy Licensing Board 5500 San Antonio
              Dr. NE, Albuquerque, NM, 87109.
            </Typography>
            <Typography component="h3" variant="h6" gutterBottom>
              Scheduling and contact
            </Typography>
            <Typography variant="body1" paragraph>
              Counseling sessions are generally scheduled in 50-minute
              increments. Being late for an appointment by 15 minutes or more
              may require that you reschedule. During COVID-19, counselors will
              not be available in-person. Sessions will take place online
              through a secure portal or via phone. If for any reason you cannot
              make it to a counseling session, please call{' '}
              <Link color="secondary" href="tel:5053109924">
                (505) 310-9924
              </Link>{' '}
              to cancel your appointment 24-hours in advance. Lack of notice or
              multiple missed sessions may result in termination of services.
              Our phone and voicemail are monitored at least once every 24-hours
              Monday-Friday. If you are in a non-emergency situation in which
              you feel immediate attention is necessary; B Be-Compassion, LLC
              counselors will make reasonable effort to make themselves
              available. If a counselor at Be-Compassion, LLC is not immediately
              available and you reach voicemail, please leave a message
              indicating that the call is urgent, and someone will get back to
              you as soon as possible. Be-Compassion, LLC does not provide
              emergency services, if you are in crisis or are experiencing an
              emergency, please call 911 or use one of the phone numbers
              provided at the end of this consent form.
            </Typography>
            <Typography component="h3" variant="h6" gutterBottom>
              Confidentiality and Limitations
            </Typography>
            <Typography variant="body1" paragraph>
              You can be assured that the services at Be-Compassion, LLC will be
              conducted in a professional manner consistent with accepted
              ethical standards. Counselors at Be-Compassion, LLC adhere to both
              the State of New Mexico’s and the American Counseling
              Association’s Code of Ethics. Your confidentiality is very
              important, and the information conveyed in the counseling session
              is honored. The nature of the counseling profession, and the
              ethics and laws that govern it, present certain limitations. Law
              protects all communications between a client and a mental health
              professional. Confidentiality and HIPPA compliance can be assured
              except in any of the following situations:
            </Typography>
            <Typography component="ol" variant="body1" paragraph>
              <li>
                You provide written consent for Be-Compassion, LLC to release
                information to another person or agency (provision of records
                may take up to 30-days).
              </li>
              <li>You indicated that you intend to harm yourself or others.</li>
              <li>
                You imply any suspicion of child abuse, abuse of the elderly, or
                abuse of a vulnerable person, (State law mandates the reporting
                of any suspected child, elderly, or disabled persons abuse or
                neglect).
              </li>
              <li>A court order is issued.</li>
              <li>
                For consultation with other professionals (who are bound by the
                same ethics of confidentiality; to what degree it is possible,
                every reasonable attempt will be made to avoid revealing your
                identity to other professionals with whom Be-Compassion, LLC
                counselors will consult.
              </li>
            </Typography>
            <Typography variant="body1" paragraph>
              In following professional standards of counseling, it should be
              expected that your counselor will regularly access professional
              consultation and supervision of their work. Any professionals that
              are consulted with are bound by the same professional, ethical,
              and legal restrictions of confidentiality.
            </Typography>
            <Typography component="h3" variant="h6" gutterBottom>
              Ending counseling
            </Typography>
            <Typography variant="body1" paragraph>
              Ending the counselor-client relationship can occur in several
              different contexts, but it is important that we be prepared for an
              ending phase from the outset of treatment. You can choose to end
              counseling at any time. Otherwise, the counseling relationship
              will end when you have realized maximum benefit from it or have
              achieved the goals that are made at the outset or it is determined
              that Be-Compassion LLC is not an appropriate setting for you, such
              as needing additional treatment that Be-Compassion, LLC does not
              provide. You may request to return to Be-Compassion, LLC if you
              would like to continue counseling at a late date or time.
            </Typography>
            <Typography
              variant="body1"
              style={{ fontWeight: 'bold' }}
              paragraph
            >
              If an emergency arises, please dial 911. Additional emergency
              resources for crisis situations are as follows:
            </Typography>
            <Typography variant="body1" component="ul" gutterBottom>
              <li>
                {'New Mexico Crisis and Access Line '}
                <Link color="secondary" href="tel:(855)-662-7474">
                  (855)-662-7474
                </Link>
              </li>
              <li>
                {'New Mexico Peer to Peer Warmline '}
                <Link color="secondary" href="tel:(855)-466-7100">
                  (855)-466-7100
                </Link>
              </li>
              <li>
                {'Suicide Prevention Lifeline '}
                <Link color="secondary" href="tel: (800) 273-8255">
                  (800) 273-8255
                </Link>
              </li>
              <li>
                {'Rape Crisis Center of Central NM Hotline '}
                <Link color="secondary" href="tel: (505) 266-7711">
                  (505) 266-7711
                </Link>
                {' or toll-free '}
                <Link color="secondary" href="tel:1-888-811-8282">
                  1-888-811-8282
                </Link>
              </li>
              <li>
                {'UNM Psychiatric Emergency Services '}
                <Link color="secondary" href="tel:(505) 272-2920">
                  (505) 272-2920
                </Link>
              </li>
              <li>
                {'Presbyterian Kaseman Hospital '}
                <Link color="secondary" href="tel:(505) 291-2000">
                  (505) 291-2000
                </Link>
              </li>
              <li>
                {
                  'Presbyterian Medical Services 24 Hour-Emergency Crisis Response of Santa Fe at '
                }
                <Link color="secondary" href="tel:1-888-920-6333">
                  1-888-920-6333
                </Link>
                {' or '}
                <Link color="secondary" href="tel:505-820-6333">
                  505-820-6333
                </Link>
              </li>
              <li>
                {
                  'CHRISTUS St. Vincent Reg Med Center Behavioral Health Unit at '
                }
                <Link color="secondary" href="tel:505-913-5540">
                  505-913-5540
                </Link>
              </li>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <form
              onSubmit={(e) => {
                e.preventDefault()
                setDialogOpen(true)
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <FormControlLabel
                      aria-required="true"
                      control={
                        <Checkbox
                          required
                          checked={q1}
                          onChange={handleQ1}
                          name="q1"
                          color="primary"
                        />
                      }
                      label="I have read, understood, agree and consent to the above conditions of service."
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <FormControlLabel
                      aria-required="true"
                      control={
                        <Checkbox
                          required
                          checked={q2}
                          onChange={handleQ2}
                          name="q2"
                          color="primary"
                        />
                      }
                      label="I have had the opportunity to ask questions regarding the above policies."
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <FormControlLabel
                      aria-required="true"
                      control={
                        <Checkbox
                          required
                          checked={q3}
                          onChange={handleQ3}
                          name="q3"
                          color="primary"
                        />
                      }
                      label="I have been provided a copy of this consent for counseling."
                    />
                  </FormControl>
                  <Grid item xs={12}>
                    <Button
                      fullWidth
                      type="submit"
                      color="secondary"
                      variant="contained"
                    >
                      Sign and Print
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Grid>
          <Dialog
            fullScreen={isMobile}
            open={dialogOpen}
            PaperProps={
              {
                component: 'form',
                onSubmit: handleSignClick,
              } as any
            }
          >
            <DialogTitle>Signing Consent for Counseling</DialogTitle>
            <DialogContent>
              <Grid container spacing={2}>
                {submitting ? (
                  <Grid item xs={12}>
                    <LinearProgress color="secondary" />
                  </Grid>
                ) : null}
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    value={name}
                    onChange={handleName}
                    label="Name"
                    type="text"
                    required
                  ></TextField>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    value={email}
                    onChange={handleEmail}
                    label="Email Address"
                    type="email"
                    required
                  ></TextField>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1">
                    You consent to signing this document electronically
                  </Typography>
                </Grid>
                <Grid item xs={12} justify="center" container>
                  <SignatureCanvas
                    ref={canvasRef}
                    onEnd={() => setSign(true)}
                    penColor="black"
                    canvasProps={{
                      className: classes.signatureCanvas,
                      width: 500,
                      height: 200,
                    }}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                fullWidth
                onClick={() => setDialogOpen(false)}
              >
                Cancel
              </Button>
              <Button
                fullWidth
                variant="contained"
                color="secondary"
                disabled={submitting}
                type="submit"
              >
                Submit
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      )}
    </Fragment>
  )
}
