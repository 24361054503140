import { FormEvent, Fragment, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link as RouterLink } from 'react-router-dom'

import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import TextField from '@material-ui/core/TextField'

export default function BookingPage({ isMobile }: { isMobile: boolean }) {
  const [locked, setLocked] = useState(true)
  const [invalid, setInvalid] = useState(false)
  const [password, setPassword] = useState('')

  return (
    <Fragment>
      <Helmet title="Consent Form | Be Compassion" />
      {locked ? (
        <Dialog
          open
          maxWidth="sm"
          fullWidth
          fullScreen={isMobile}
          PaperProps={
            {
              component: 'form',
              onSubmit: (event: FormEvent<HTMLFormElement>) => {
                event.preventDefault()

                if (password === 'BookMe') {
                  setLocked(false)
                } else {
                  setLocked(true)
                  setInvalid(true)
                }
              },
            } as any
          }
        >
          <DialogContent>
            <Box display="flex" height="100%" alignItems="center" flexGrow="1">
              <TextField
                required
                fullWidth
                variant="outlined"
                label="Unlock code"
                value={password}
                error={invalid}
                onChange={(event) => setPassword(event.target.value)}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              fullWidth
              variant="contained"
              to="/services"
              component={RouterLink}
            >
              Cancel
            </Button>
            <Button
              fullWidth
              color="secondary"
              variant="contained"
              type="submit"
            >
              Unlock
            </Button>
          </DialogActions>
        </Dialog>
      ) : (
        <iframe
          className="booking-frame"
          title="Booking Frame"
          frameBorder="0"
          src="https://squareup.com/appointments/book/1fs4fjmgb3ymmy/3MDJ50N31NRB3/services"
        ></iframe>
      )}
    </Fragment>
  )
}
