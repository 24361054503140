import { cloneElement, Fragment } from 'react'
import { Link as RouterLink, useRouteMatch } from 'react-router-dom'

import AppBar from '@material-ui/core/AppBar'
import Box from '@material-ui/core/Box'
import Link from '@material-ui/core/Link'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import useScrollTrigger from '@material-ui/core/useScrollTrigger'
import { NavItem, NavMenu } from '@mui-treasury/components/menu/navigation'

function ElevationScroll(props: any) {
  const { children } = props
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  })

  return cloneElement(children, {
    elevation: trigger ? 4 : 0,
  })
}

function MenuLink({ label, to }: { label: string; to: string }) {
  let match = useRouteMatch({
    path: to,
    exact: true,
  })

  return (
    <Link component={RouterLink} to={to}>
      <NavItem active={match} as={'div'}>
        {label}
      </NavItem>
    </Link>
  )
}

const itemHorzPadding = 2
const useNavigationStyles = makeStyles(({ palette, spacing }: Theme) =>
  createStyles({
    menu: {
      display: 'flex',
      overflow: 'auto',
    },
    item: ({ gutter }: any) => ({
      flexShrink: 0,
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
      borderRadius: 4,
      padding: spacing(1, itemHorzPadding),
      cursor: 'pointer',
      textDecoration: 'none !important',
      transition: '0.2s ease-out',
      color: palette.primary.contrastText,
      '&:after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        bottom: 0,
        left: '50%',
        width: 6,
        height: 6,
        borderRadius: 50,
        transform: 'scale(0) translateX(-50%)',
        transition: '0.3s ease-out',
        opacity: 0,
        backgroundColor: palette.secondary.light,
      },
      '&:hover': {
        color: palette.primary.contrastText,
        '&:after': {
          opacity: 1,
          transform: 'scale(1) translateX(-50%)',
        },
      },
      '&:not(:first-child)': {
        marginLeft: typeof gutter === 'number' ? spacing(gutter) : gutter,
      },
    }),
    itemActive: {
      '&$item': {
        color: palette.secondary.light,
        '&:after': {
          opacity: 1,
          width: 40,
          backgroundColor: palette.secondary.light,
          transform: 'scale(1) translateX(-50%)',
        },
      },
    },
  })
)

export default function Header({ isMobile }: { isMobile: boolean }) {
  return (
    <ElevationScroll>
      <AppBar>
        {isMobile ? (
          <Fragment>
            <Toolbar>
              <Box display="flex" flexGrow="1" justifyContent="center">
                <img
                  alt="Be Compassion Logo"
                  height="64"
                  src="/logo-white.png"
                />
              </Box>
            </Toolbar>
            <Toolbar>
              <Box display="flex" flexGrow="1" justifyContent="center">
                <NavMenu useStyles={useNavigationStyles}>
                  <MenuLink to="/" label="Home" />
                  <MenuLink to="/services" label="Services" />
                  <MenuLink to="/contact" label="Contact" />
                </NavMenu>
              </Box>
            </Toolbar>
          </Fragment>
        ) : (
          <Toolbar>
            <Box flexGrow="1"></Box>
            <img alt="Be Compassion Logo" height="64" src="/logo-white.png" />
            <Box flexGrow="1"></Box>
            <NavMenu useStyles={useNavigationStyles}>
              <MenuLink to="/" label="Home" />
              <MenuLink to="/services" label="Services" />
              <MenuLink to="/contact" label="Contact" />
            </NavMenu>
            <Box flexGrow="1"></Box>
          </Toolbar>
        )}
      </AppBar>
    </ElevationScroll>
  )
}
