import { createMuiTheme } from '@material-ui/core/styles'

const theme = createMuiTheme({
  typography: {
    fontFamily: 'Roboto',
  },
  palette: {
    type: 'light',
    primary: {
      light: '#484848',
      main: '#212121',
      dark: '#000000',
      contrastText: '#fff',
    },
    secondary: {
      light: '#666ad1',
      main: '#303f9f',
      dark: '#001970',
      contrastText: '#fff',
    },
  },
})

export default theme
