import { ChangeEvent, FormEvent, useCallback, useState } from 'react'
import { Helmet } from 'react-helmet'

import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'

const useContactStyles = makeStyles(({ breakpoints }: Theme) =>
  createStyles({
    subtitle: {
      fontFamily: `'Dancing Script'`,
    },
    wrapper: {
      display: 'flex',
      alignContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    },
    form: {
      maxWidth: 600,
      width: '100%',
    },
  })
)

function submitContact(
  name: string,
  email: string,
  phone: string,
  message: string
) {
  return fetch(
    `/function/contact?email=${encodeURIComponent(
      email
    )}&name=${encodeURIComponent(name)}&phone=${encodeURIComponent(
      phone
    )}&message=${encodeURIComponent(message)}`,
    {
      method: 'POST',
    }
  )
}

export default function Contact() {
  const classes = useContactStyles()
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [message, setMessage] = useState('')
  const [submitted, setSubmitted] = useState(false)

  const handleName = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => setName(event.target.value),
    [setName]
  )

  const handlePhone = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => setPhone(event.target.value),
    [setPhone]
  )
  const handleEmail = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => setEmail(event.target.value),
    [setEmail]
  )
  const handleMessage = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => setMessage(event.target.value),
    [setMessage]
  )

  const handleSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault()

      submitContact(name, email, phone, message).then(
        () => {
          setSubmitted(true)
        },
        () => {
          alert('something went wrong with submitting the contact form')
        }
      )
    },
    [name, email, phone, message]
  )

  return (
    <div className={classes.wrapper}>
      <Helmet title="Contact | Be Compassion" />
      <Typography
        align="center"
        className={classes.subtitle}
        variant="h4"
        gutterBottom
      >
        {submitted
          ? 'Thank you for submitting your request, I will try to respond as soon as possible.'
          : 'Contact'}
      </Typography>
      {submitted ? null : (
        <Grid
          className={classes.form}
          container
          spacing={2}
          component="form"
          onSubmit={handleSubmit}
        >
          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              type="text"
              variant="outlined"
              label="Name"
              value={name}
              onChange={handleName}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              type="email"
              variant="outlined"
              label="Email"
              value={email}
              onChange={handleEmail}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              type="phone"
              variant="outlined"
              label="Phone"
              value={phone}
              onChange={handlePhone}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              multiline
              rows={10}
              fullWidth
              required
              type="text"
              variant="outlined"
              label="Message"
              value={message}
              onChange={handleMessage}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              type="submit"
              fullWidth
              color="secondary"
            >
              Submit
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" paragraph align="center">
              You will get a response as soon as possible.
            </Typography>
          </Grid>
        </Grid>
      )}
      <Typography variant="body1" paragraph align="center">
        If you are having an emergency please call 911.
        <br />
        <br />
        If you are in crisis please use the resources below:
        <br />
        <Typography variant="h6">New Mexico Crisis and Access Line</Typography>
        <Link color="secondary" href="tel:1-855-662-7474">
          1-855-NMCRISIS (662-7474)
        </Link>
        <br />
        TTY
        <Link color="secondary" href="tel:1-855-227-5485">
          1-855-227-5485
        </Link>
        <br />
        7 days a week, 24 hours a day, 365 days a year
        <br />
        <br />
        <Typography variant="h6">New Mexico Peer to Peer Warmline</Typography>
        <Link color="secondary" href="tel:1-855-466-7100">
          1-855-4NM-7100 (466-7100)
        </Link>
        <br />
        Call 3:30pm – 11:30pm or text 6pm – 11pm every day
      </Typography>
    </div>
  )
}
