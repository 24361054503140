import { Helmet } from 'react-helmet'

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

const useHomeStyles = makeStyles(({ breakpoints }: Theme) =>
  createStyles({
    subtitle: {
      fontFamily: `'Dancing Script'`,
    },
    mainLogo: {
      maxWidth: 600,
      width: `100%`,
    },
    wrapper: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    },
  })
)

export default function Home() {
  const classes = useHomeStyles()

  return (
    <div className={classes.wrapper}>
      <Helmet title="Home | Be Compassion" />
      <img
        alt="Be Compassion Logo"
        src="/logo.png"
        className={classes.mainLogo}
      />
      <Typography variant="h4" className={classes.subtitle}>
        Compassionate counseling, supervision and consulting
      </Typography>
    </div>
  )
}
